<template>
  <h4>{{ title }}</h4>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped>
h4 {
  margin: 3% 0;
  color: black;
}
</style>
