<template>
  <div>
    <ImageHeader
      :text="
        isLoading
          ? 'Lade..'
          : matches.length == 0
          ? 'Keine Spiele vorhanden'
          : 'Bundesliga'
      "
      imageUrl="http://h-team.torbara.com/images/soccer/page-header-bg.jpg"
    />
    <div v-if="isLoading">
      <div class="container loadingContainer">
        <LoadingComponent />
      </div>
    </div>

    <div v-else>
      <div class="container">
        <Title title="Spielübersicht" />
        <transition name="fade">
          <InfoBox
            v-show="errorStatus !== null"
            :text="errorStatus"
            linkText=""
            link=""
            isClosable="no"
          />
        </transition>

        <div v-if="errorStatus === null">
          <div class="row m-2">
            <div class="col-md-2 mt-2">
              <span>Spieltag wählen:</span>
            </div>
            <div class="col-md-10 spieltagContainer">
              <div class="wrap">
                <input
                  type="number"
                  min="1"
                  @change="loadData()"
                  v-model="spieltag"
                  value="Spieltag suchen"
                />
                <button><i class="fa fa-search" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>

          <div v-if="matches.length > 0">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="main-lates-matches">
                <div v-for="match in matches" :key="match.id" class="item">
                  <span class="championship"
                    >{{ germanDate(match.dateTime) }} Uhr</span
                  >
                  <span class="teams-wrap">
                    <span class="team">
                      <span>
                        <img :src="match.team1.image" alt="team-image" />
                      </span>
                      <span>{{ match.team1.name }}</span>
                    </span>
                    <span class="score" v-if="match.matchResults.length > 0">
                      <span
                        >{{ match.matchResults[0].pointsTeam1 }} -
                        {{ match.matchResults[0].pointsTeam2 }}</span
                      >
                    </span>
                    <span class="score" v-if="match.matchResults.length == 0">
                      <span> - </span>
                    </span>
                    <span class="team1">
                      <span>{{ match.team2.name }}</span>
                      <span>
                        <img :src="match.team2.image" alt="team-image"
                      /></span>
                    </span>
                  </span>
                  <span class="halbzeit" v-if="match.matchResults.length > 0"
                    >(Halbzeit: {{ match.matchResults[1].pointsTeam1 }} -
                    {{ match.matchResults[1].pointsTeam2 }})</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/app/components/loading.vue";
import ImageHeader from "../components/image-header.vue";
import InfoBox from "../components/info-box.vue";
import Title from "@/app/components/title.vue";

export default {
  components: {
    LoadingComponent,
    ImageHeader,
    InfoBox,
    Title,
  },
  data() {
    return {
      spieltag: "",
      status: "",
      matches: [],
    };
  },
  computed: {
    errorStatus() {
      return this.$store.getters.error;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    germanDate(dateTime) {
      const date = new Date(dateTime);
      const dString = date.toLocaleString("de-DE");
      return dString.substring(0, dString.length - 3);
    },

    async loadData() {
      await this.$store.dispatch("loadMatches", this.spieltag);
      if (this.errorStatus) {
        return;
      }

      this.matches = this.$store.getters.matches;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.main-lates-matches .item {
  padding: 15px 30px 15px 30px;
  border-bottom: 1px solid #f7f7f7;
  display: block;
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.main-lates-matches .item:hover {
  background: #fc0;
}
.main-lates-matches .item .championship {
  padding-bottom: 4px;
  clear: both;
  font-size: 13px;
  color: #999;
  line-height: normal;
  font-family: Montserrat, sans-serif;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
.main-lates-matches .item:hover .championship {
  color: #333;
}
.main-lates-matches .item .teams-wrap {
  width: 100%;
  display: inline-block;
  font-size: 0;
  transition: all 1s ease;
}
.main-lates-matches .item:hover .teams-wrap {
  width: 90%;
  margin-left: 5%;
}
.main-lates-matches .item .team {
  width: 40%;
  display: inline-block;
  font-size: 15px;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  color: #141414;
}
.main-lates-matches .item .team span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.main-lates-matches .item .team img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: contain;
}
.main-lates-matches .item .teams-wrap > * {
  vertical-align: middle;
}
.main-lates-matches .item .score {
  width: 20%;
  display: inline-block;
  text-align: center;
}
.main-lates-matches .item .score span {
  min-width: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  display: inline-block;
  background: #141414;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  border-radius: 10px;
}
.main-lates-matches .item .team1 {
  width: 40%;
  display: inline-block;
  font-size: 15px;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  color: #141414;
  text-align: right;
}

.main-lates-matches .item .team1 span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.main-lates-matches .item .team1 img {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  object-fit: contain;
}
.main-lates-matches .item .halbzeit {
  font-size: 13px;
  color: #999;
  line-height: normal;
  font-family: Montserrat, sans-serif;
  display: block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.7s;
}
.main-lates-matches .item:hover .halbzeit {
  color: #333;
}
.wrap {
  border: 1px solid #e0e0e0;
  position: relative;
}
input[type="number"] {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 20px;
  height: 48px;
  line-height: 56px;
  font-size: 14px;
  color: #8f8f8f;
  background: 0 0;
  border: none;
}
button {
  position: absolute;
  font-size: 13px;
  color: #999;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background: 0 0;
  border: none;
}

@media (max-width: 753px) {
  .team,
  .team1 {
    font-size: 12px !important;
  }
  .main-lates-matches .item .teams-wrap > * {
    text-align: center;
  }
  img {
    width: 60px !important;
    height: 60px !important;
    /* display: block !important; */
  }

  img::after {
    content: "\a" !important;
    white-space: pre;
  }
  .team span::after,
  .team1 span::after {
    content: "\a" !important;
    white-space: pre;
  }

  .spieltagContainer {
    margin-bottom: 30px;
  }
}
</style>
